<script lang="ts">
  import Navbar from "@/components/navbar.svelte";
  import { Image } from "@/components/ui/image";
  import { Typography } from "@/components/ui/typography";
  import ReviewsIOLogo from "@/images/review_logo.png?raw";
  // imgs imports
  import StarsIcons from "@/images/stars.svg";

  export let background: any = undefined;
  export let title = "";
  export let subtitle = "";
  export let showReviews = true;
</script>

<div class="relative flex flex-col">
  <div class="absolute inset-0 -z-50 -mb-16 opacity-30">
    <div
      class=" absolute inset-0 z-10"
      style="background: linear-gradient(0deg, rgba(155, 48, 255, 0.0) 0%, rgba(155, 48, 255, 0.25) 70%), linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, #121212 100%)" />
    <Image
      src={background}
      loading="eager"
      class=" h-full object-cover object-center" />
  </div>

  <Navbar />

  <div class="relative flex items-center justify-center py-8 lg:py-20">
    <header
      class="flex max-w-3xl flex-col items-center justify-center gap-8 px-6">
      <div class="flex flex-col items-center gap-4 text-start lg:text-center">
        <Typography variant="h1">
          {title}
        </Typography>
        <Typography class="!font-normal" variant="h4" as="h2" color="muted">
          {@html subtitle}
        </Typography>
      </div>

      {#if showReviews}
        <div
          class="rounded-[8px] bg-gradient-to-r from-dark-gray via-accent to-dark-gray p-[1px]">
          <div
            class="flex min-h-10 w-fit items-center justify-center gap-3 rounded-[8px] px-4 py-[10px]"
            style="background: radial-gradient(51.8% 88.16% at 50% 100%, rgba(255, 255, 255, 0.05) 0%, rgba(18, 18, 18, 0.05) 100%), #121212;">
            <Typography>Excellent</Typography>
            <Image src={StarsIcons} class="!w-[100px]" />
            <Typography class="hidden md:block">2,595+ Reviews on</Typography>
            <a
              href="https://www.reviews.io/company-reviews/store/smurfers"
              target="_blank">
              <Image
                class="!w-[115px] !min-w-[115px] cursor-pointer object-contain"
                src={ReviewsIOLogo} />
            </a>
          </div>
        </div>
      {/if}
    </header>
  </div>
</div>
